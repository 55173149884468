import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import * as styles from "./render.module.scss";

const Render = () => {
  return (
    <div className={styles.container}>
        <div className={styles.left}>
            <h3>Studio-grade 3D Renders</h3>
            <p>Whatever your level, 3D designing has never been easier. Use our pre-made templates or dive into custom camera & environment controls to create 3D content.
            </p>

            <div className={styles.features}>
              <div className={styles.feature}>
                <h4>
                  3D Snapshots
                </h4>
                <p>
                  Tweak colours, position, angles and screen media to create your perfect mockup.
                </p>
              </div>
              <div className={styles.feature}>
                <h4>
                  3D Animations
                </h4>
                <p>
                  Create animated scenes and set your devices in motion. Perfect for showcasing video interactions.
                </p>
              </div>
            </div>
            <a href="/mockups/3d">View 3D Templates</a>
        </div>
        <div className={styles.right}>
        <StaticImage
     src='../../../../assets/images/illustrations/iphone-3d-render.png'
     alt=""
     placeholder="blurred"
     layout="constrained"
     width={700}
   />
        </div>
    </div>
  );
};

export default Render;
