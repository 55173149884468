import React from "react";
import SEO from "../components/seo";
import Header from '../components/Pages/Homepage/Header/Header'
import Features from '../components/Pages/Homepage/Features/Features'
import Render from '../components/Pages/Homepage/Render/Render'
import Uses from '../components/Pages/Homepage/Uses/Uses'
import Slider from '../components/Pages/Homepage/Slider/Slider'
import Devices from '../components/Pages/Homepage/Devices/Devices'
import Pricing from '../components/Common/Pricing/Pricing'
import Layout from "../components/layout";


export default () => {
  return (
    <Layout>
      <SEO
        title={"Previewed - Free mockup generator for your app"}
        description={
          "Join Previewed to create stunning 3D image shots and animations for your app. Choose from hundreds of ready made mockups, or create your own."
        }
        ogDescription={
          "Create beautiful mockups for your app, in just a few clicks."
        }
        twitterDescription={
          "Create beautiful mockups for your app, in just a few clicks."
        }
      />
        <Header />
        <Slider />
        <Uses />
        <Render />
        <Devices />
        <Features />
        <Pricing />
    </Layout>
  );
};
