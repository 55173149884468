// extracted by mini-css-extract-plugin
export const container = "features-module--container--2fjai";
export const inner = "features-module--inner--2Rtiw";
export const featurePointsContainer = "features-module--featurePointsContainer--3ri0j";
export const featureGrid = "features-module--featureGrid---3jKF";
export const features = "features-module--features--3lKo5";
export const left = "features-module--left--1lvNb";
export const feature = "features-module--feature--H2a96";
export const active = "features-module--active--30TtJ";
export const text = "features-module--text--M0cNO";
export const right = "features-module--right--2jtWR";