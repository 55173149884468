import React from 'react';
import TextLoop from "react-text-loop";
import CTA from './CTA/CTA';
import Example from './Example/Example';
import * as styles from './header.module.scss';


const HomepageHeader = () => {
    return(
        <div className={styles.container}>
            <div className={styles.inner}>
            <h1>
                Create Awesome Mockups <br />for <span className={styles.textloop}><TextLoop mask={true} children={["App Stores", "Presentations", "Social Media", "Portfolios", "Blogs", "GitHub", "iOS Apps", "Display Ads", "Product Launches", "Pitch Decks", "Android Apps"]} /></span>
            </h1>
            <h3>
                The simplest way to create device mockups. <br />
                All in your browser.
            </h3>
            <div className={styles.search}>
                {/* <Search /> */}
                <CTA />
            </div>
            {/* <span className={styles.howitworks}>Watch how it works</span> */}
            <span id="demo"></span>
            <Example />
        </div>             
        </div>
    )
}

export default HomepageHeader;