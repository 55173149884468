import React from 'react'
import * as styles from './cta.module.scss'




const CTA = () => {

    function onClick(){
        document.getElementById('demo').scrollIntoView({
          behavior: 'smooth'
        });
    }

    return(
        <div className={styles.buttons}>
            <a href="/mockups" className={styles.button}>
                Browse Templates
            </a>
            <a onClick={onClick} className={styles.button2}>
                Live Demo
            </a>
        </div>
    )
}


export default CTA;