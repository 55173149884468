import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import * as styles from './slider.module.scss';
import PhoneVideo from '../../../../assets/videos/3d-mockup-animation.mp4';


const FeaturesContainer = () => {
  const [openTab, setOpenTab] = React.useState(0);

  const tabs = [
    {
      icon: "something",
      title: "2D Images",
      subtitle:
        "Make your app stand out.\nPerfect for Google Play and App Store.",
    },
    {
      icon: "something",
      title: "3D Snapshots",
      subtitle: "Beautiful from all angles.\nPerfect for promotional banners.",
    },
    {
      icon: "something",
      title: "3D Animations",
      subtitle:
        "Bring your mockups to life.\nPerfect for blog and social media posts.",
    },
  ];

  const FeatureTabs = () =>
    tabs.map(({ icon: Icon, title, subtitle }, index) => {
      return (
        <div
          key={index}
          className={`${styles.feature} ${openTab === index && styles.active}`}
          onClick={() => setOpenTab(index)}
        >
          {/* <div className={styles.icon}>
              <Icon />
            </div> */}
          <div className={styles.text}>
            <span>{title}</span>
            <span>{subtitle}</span>
          </div>
        </div>
      );
    });

  return (
    <div className={styles.container}>
      <div className={styles.inner}>
        <h2>The only mockup tool you need</h2>
        <h3>
          To level up your presentation and engage your audience. 
          <br />
          Take snapshots & create animation scenes, all in 3D.
        </h3>
        <div className={styles.features}>
          <div className={styles.left}>
            <FeatureTabs />
          </div>
          <div className={styles.right}>
            {openTab === 0 ? (
              <StaticImage
                src="../../../../assets/images/illustrations/2d-mockup-example.png"
                alt="2D Mockups use case"
                placeholder="blurred"
                layout="constrained"
                objectFit={"contain"}
                height={500}
                key={"2d-image"}
              />
            ) : openTab === 1 ? (
              <StaticImage
                src="../../../../assets/images/illustrations/3d-mockup-example.png"
                alt="3D Mockups use case"
                placeholder="blurred"
                layout="constrained"
                objectFit={"contain"}
                height={500}
                key={"3d-image"}
              />
            ) : (
              <video
                src={PhoneVideo}
                alt={"3D Animation example"}
                autoPlay
                muted
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};


export default FeaturesContainer
