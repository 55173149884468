import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import * as styles from "./uses.module.scss";

const Uses = () => {
  return (
    <div className={styles.container}>
      <h3>Popular Use Cases</h3>
        <div className={styles.features}>
          <div className={styles.feature}>
            <div className={styles.img}>
              <StaticImage
                  src='images/appstore_screenshots.jpg'
                  alt="App Store and Google Play Screenshots"
                  placeholder="blurred"
                  layout="constrained"
                  height={250}
              />
            </div>
            <h4>
              App Store Screenshots
            </h4>
            <p>
              Generate panoramic mockups for iOS and Android apps.
            </p>
            <p><a href="/app-store-screenshot-generator">See App Store Screenshot Maker</a></p>
          </div>
          <div className={styles.feature}>
          <div className={styles.img}>
            <StaticImage
                src='images/iphone_mockups.png'
                alt="iPhone mockup"
                placeholder="blurred"
                layout="constrained"
                height={250}
            />
          </div>
            <h4>
              Device Mockups
            </h4>
            <p>
              Make your app presentations stand out. Use our device mockups.
            </p>
            <p><a href="/mockups/apple/iphone">Browse iPhone Mockups</a></p>
          </div>
          <div className={styles.feature}>
            <div className={styles.img}>
              <StaticImage
                  src='images/device_animations.png'
                  alt="iPhone mockups"
                  placeholder="blurred"
                  layout="constrained"
                  height={250}
              />
            </div>
            <h4>
              App Promo Videos
            </h4>
            <p>
              Render 3D device animation scenes for app promo videos.
            </p>
            <p><a href="/app-promo-video">Create an App Promo Video</a></p>
          </div>
        </div>
    </div>
  );
};

export default Uses;
