import React from "react";
import ColorPalleteIcon from '../../../../assets/svg/homepage/color-palette.svg';
import DatabaseIcon from '../../../../assets/svg/homepage/database.svg';
import DimensionsIcon from '../../../../assets/svg/homepage/dimensions.svg';
import FileIcon from '../../../../assets/svg/homepage/file.svg';
import FontIcon from '../../../../assets/svg/homepage/font.svg';
import GroupIcon from '../../../../assets/svg/homepage/group.svg';
import PictureIcon from '../../../../assets/svg/homepage/picture.svg';
import PinIcon from '../../../../assets/svg/homepage/pin.svg';
import PhoneIcon from '../../../../assets/svg/homepage/smartphone.svg';
import Points from '../../../Common/Points/Points';
import * as styles from "./features.module.scss";


const FeaturePoints = () => {
    const points = [
        {
            icon: FontIcon,
            title: 'Texts',
            subtitle: 'Choose from 100s of fonts and add them to your mockups.'
        },
        {
            icon: PictureIcon,
            title: 'Images',
            subtitle: 'Add custom images or use our collections to create mockups with unique elements.'
        },
        {
            icon: PhoneIcon,
            title: 'Devices',
            subtitle: 'iPhones, Android phones, Tablets, Laptops, Monitor Displays and much more.'
        },
        {
            icon: DimensionsIcon,
            title: 'Dimensions',
            subtitle: 'Change sizes of all components that make up your mockup - text, images & devices.'
        },

        {
            icon: ColorPalleteIcon,
            title: 'Colors & Transparency',
            subtitle: 'Set custom colors for devices, texts & backgrounds. We also support the alpha (transparency) channel.'
        },
        {
            icon: PinIcon,
            title: 'Positioning',
            subtitle: 'Position and rotate any component, however you like. Simply drag and drop.'
        },
        {
            icon: FileIcon,
            title: 'Exports',
            subtitle: 'Export your mockups in dimensions and format that suit your requirements. Choose from jpeg, png and mp4.'
        },
        {
            icon: GroupIcon,
            title: 'Teams',
            subtitle: 'Share your work with others. Invite your team members to your mockup groups and collaborate together.'
        },
        {
            icon: DatabaseIcon,
            title: 'Template Storage',
            subtitle: `All saved templates are backed up in the cloud. Come back to your collection at any time.`
        },
    ]

    return(
        <div className={styles.featurePointsContainer}>
            <h2>Fully Customizable</h2>
            <h3>Change texts, add images, adjust sizes and position any element - all at your fingertips.</h3>
            <div className={styles.featureGrid}>
                <Points points={points}/>
            </div>
        </div>
    )
}

const Features = () => {
  return (
    <div className={styles.container}>
      <div className={styles.inner}>
        <FeaturePoints />
      </div>
    </div>
  );
};

export default Features;
