// extracted by mini-css-extract-plugin
export const container = "slider-module--container--2laeX";
export const inner = "slider-module--inner--3tnnz";
export const featurePointsContainer = "slider-module--featurePointsContainer--2QJzF";
export const featureGrid = "slider-module--featureGrid--37slV";
export const features = "slider-module--features--29h2q";
export const left = "slider-module--left--3m1w1";
export const feature = "slider-module--feature--14Uws";
export const active = "slider-module--active--16nBb";
export const text = "slider-module--text--2OIRg";
export const right = "slider-module--right--1Xa6Q";