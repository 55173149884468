import React from 'react'
import * as styles from './points.module.scss'

const Points = ({points}) => points.map(({icon: Icon, title, subtitle}, index) => {
    return(
        <div key={index} className={styles.point}>
            {Icon ?
            <div className={styles.icon}>
                <Icon />
            </div> : null}
            <h4>{title}</h4>
            <h5>{subtitle}</h5>
        </div>
    )
})

export default Points;